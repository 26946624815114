































































import "../scss/components/preferencesBlock.scss";
import axios, { AxiosResponse, AxiosError } from "axios";

import { Watch, Prop, Component, Vue } from "vue-property-decorator";
import { SwitcherBlock } from "@frontiers-vue/switcher";
import { SearchDropDown } from "@frontiers-vue/search-input";
import { TagCloud, ITag, TagType } from "@frontiers-vue/tag-cloud";
import { Tooltip, Enums } from "@frontiers-vue/tooltip";

import { ISubscriptionType, ISubscriptionResource } from "../core/models";
import EventBus, {
  HIDE_TOOLTIP_EVENT,
  ACTIVATE_ALL_EVENT,
  SHOW_TOAST_MESSAGE
} from "../core/event-bus";

@Component({
  components: { SwitcherBlock, SearchDropDown, TagCloud, Tooltip }
})
export default class PreferencesBlock extends Vue {
  private get tooltipDirection(): any {
    return this.windowWidth > 1420
      ? Enums.Direction.Right
      : Enums.Direction.Bottom;
  }

  private get tooltipStyles(): any {
    if (this.windowWidth > 1420 && this.elClickedParent !== null) {
      const topPosition = this.elClickedParent.offsetTop + 32;
      return { top: `${topPosition}px` };
    } else if (this.elClickedParent !== null) {
      const topPosition = this.elClickedParent.offsetTop + 55;
      return { top: `${topPosition}px` };
    }
    return null;
  }

  private get filteredFOSs(): any {
    const currentFOSs = this.currentFOSs;
    if (currentFOSs) {
      let result = this.articleAlertsFOS.filter(f => {
        return currentFOSs.find(f2 => f2.data.id === f.id) === undefined;
      });
      return result;
    } else {
      return [];
    }
  }

  private get currentFOSs(): any[] {
    const articleAlertsType = this.subscriptionTypes.find(f =>
      this.allowFOSResources(f)
    );

    return articleAlertsType ? articleAlertsType.subscribedToResources : [];
  }

  private get isDisabledFOSSelection(): boolean {
    const articleAlertsType = this.subscriptionTypes.find(f =>
      this.allowFOSResources(f)
    );
    return articleAlertsType ? !articleAlertsType?.isSubscribed : true;
  }

  @Prop({ required: true }) public id!: string;
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public description!: string;
  @Prop({ required: true }) public subscriptionTypes!: ISubscriptionType[];
  @Prop({ default: false }) private isDisabled!: boolean;

  private windowWidth: number = 0;
  private tooltipIsVisible: boolean = false;

  private inputText: string = "";
  private raiseError: boolean = false;

  private articleAlertsFOS: ISubscriptionResource[] = [];

  private newTag: string = "";

  private elClickedParent: HTMLElement | null = null;

  private switcherBlock_changed(
    subscriptionTypeId: number,
    stateOn: boolean
  ): void {
    const type = this.subscriptionTypes.find(
      f => f.subscriptionTypeId === subscriptionTypeId
    );
    if (type) {
      const okCallback = () => {
        type.isSubscribed = stateOn;
      };
      const koCallback = () => {
        stateOn = !stateOn;
      };

      this.manageSubscription(
        subscriptionTypeId,
        stateOn,
        okCallback,
        "",
        "",
        koCallback
      );
    } else {
      EventBus.$emit(SHOW_TOAST_MESSAGE, "You miss some reference", "Error");
    }
  }

  private allowFOSResources(subscriptionType: ISubscriptionType): boolean {
    return (
      subscriptionType.resourceType?.toLowerCase()?.trim() === "fieldofstudy" &&
      subscriptionType.isOptIn === true
    );
  }

  private tooltip_show(event: any): void {
    EventBus.$emit(HIDE_TOOLTIP_EVENT);
    this.tooltipIsVisible = true;
    this.elClickedParent = event.currentTarget.parentElement;
  }

  private reactivate_all(): void {
    EventBus.$emit(ACTIVATE_ALL_EVENT);
    this.tooltipIsVisible = false;
  }

  private tooltip_close(): void {
    this.tooltipIsVisible = false;
  }

  private searchDropDown_selectOption(
    type: ISubscriptionType,
    option: any
  ): void {
    const selectedTag: ITag = {
      text: option.value,
      type: TagType.Info,
      data: option
    };
    if (
      !type.subscribedToResources.find(
        resource => resource.data.id === option.id
      )
    ) {
      const okCallback = () => {
        type.subscribedToResources.push(selectedTag);
      };

      this.manageSubscription(
        type.subscriptionTypeId,
        true,
        okCallback,
        option.id,
        option.value
      );
    } else {
      EventBus.$emit(SHOW_TOAST_MESSAGE, "You miss some reference", "Error");
    }
    this.inputText = "";
  }

  private searchDropDown_reset(): void {
    this.inputText = "";
  }

  private tagCloud_remove(type: ISubscriptionType, index: number): void {
    let element = type.subscribedToResources[index].data;
    const okCallback = () => {
      type.subscribedToResources.splice(index, 1);
    };

    this.manageSubscription(
      type.subscriptionTypeId,
      false,
      okCallback,
      element.id,
      element.value
    );
  }

    private loadKeywords(): void {      
      const url = `/emails/api/v1/fieldsOfStudy`;
        axios
        .get(url)
          .then((response: AxiosResponse) => {
            this.articleAlertsFOS = response.data as ISubscriptionResource[];
            this.articleAlertsFOS.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)); 
        })
        .catch((reason: AxiosError) => {
          EventBus.$emit(SHOW_TOAST_MESSAGE, "Something went wrong retrieving Article Alerts fields", "Error");
        });
  }

  @Watch("subscriptionTypes")
  private subscriptionTypes_updated(): void {
    //tTHIS.SELECTEDFOS = his.subscriptionTypes.find('ARTICLES')?.resources.MAP(M => M);
  }

  @Watch("isDisabled")
  private desactivateAllSwitchers(value: boolean): void {
    if (!value) {
      this.tooltipIsVisible = false;
    }
  }

  private setWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  private mounted(): void {
    window.addEventListener("resize", this.setWindowWidth);
    EventBus.$on(HIDE_TOOLTIP_EVENT, () => {
      this.tooltipIsVisible = false;
    });
  }

  private created(): void {
    if (this.subscriptionTypes.find(f =>
      this.allowFOSResources(f))){
      this.loadKeywords();
    }
    this.setWindowWidth();
        this.tooltipIsVisible = false;
  }

  private beforeDestroy(): void {
    window.removeEventListener("resize", this.setWindowWidth);
  }

  private manageSubscription(
    subscriptionId: number,
    switchOn: boolean,
    okCallback: any,
    resourceId: string = "",
    resourceValue: string = "",
    errCallback: any = undefined
  ): void {
    let token = encodeURIComponent(this.$route.params.urlKey) as string | null;

    if (!token || token === "undefined") {
      token = null;
    }

    let url = "/emails/api/v1/";
    let data: any = {
      UrlKey: token,
      SubscriptionTypeId: subscriptionId,
      ResourceId: resourceId.toString()
    };

    if (switchOn) {
      url += `subscribe`;
      data.ResourceValue = resourceValue.toString();
    } else {
      url += `unsubscribe`;
    }

    axios
      .post(url, data)
      .then(response => {
        EventBus.$emit(
          SHOW_TOAST_MESSAGE,
          "Your changes are saved automatically",
          "Info"
        );
        if (typeof okCallback === "function") {
          okCallback();
        }
      })
      .catch((error: AxiosError) => {
        // rollback the change
        EventBus.$emit(SHOW_TOAST_MESSAGE, "Something went wrong", "Error");
        if (typeof errCallback === "function") {
          errCallback();
        }
      });
  }
}
