/**
 * The DocScroll directive is only enable when the binded offset is greater than zero.
 */
export class Scroll {
  private static get CALLBACK_FUNC(): string { return 'setWindowLocation'; }

  private static lastScrollDirection: string = 'down';
  private static scrollY: number = 0;

  public bind(el: HTMLElement, binding: any, vnode: any): void {

    vnode.context[Scroll.CALLBACK_FUNC] = () => {
      if (window.innerWidth < 769) { return; }

      let scrollDirection: string = '';
      let isElActive;

      const newLocation = binding.value.location;
      const screenPosition = el.getBoundingClientRect().top;
      const offset = 51;

      // checking scroll direction
      if (window.pageYOffset > Scroll.scrollY) {
        scrollDirection = 'down';

      } else if (window.pageYOffset < Scroll.scrollY) {
        scrollDirection = 'up';

      } else if (window.pageYOffset === Scroll.scrollY) {
        scrollDirection = Scroll.lastScrollDirection;
      }

      // checking if the element can be affected, this means is visible partially in the screen
      const topAppearsOnTopScreen = window.pageYOffset >= el.offsetTop - offset;
      const bottomAppearsOnBottomScreen = window.pageYOffset < (el.offsetHeight + el.offsetTop - offset);
      if (topAppearsOnTopScreen && bottomAppearsOnBottomScreen) {
        isElActive = true;
      } else {
        isElActive = false;
      }

      if (isElActive) {
        if (scrollDirection === 'down') {
          window.location = newLocation;

          if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            window.location = binding.value.lastLocation;
          }

        } else if (scrollDirection === 'up' && screenPosition >= offset) {
          window.location = newLocation;
        }
      }

      Scroll.lastScrollDirection = scrollDirection;
      Scroll.scrollY = window.pageYOffset;
    };

    document.addEventListener('scroll', vnode.context[Scroll.CALLBACK_FUNC]);
  }

  public unbind(el: HTMLElement, binding: any, vnode: any): void {
    document.removeEventListener('scroll', vnode.context[Scroll.CALLBACK_FUNC]);
  }
}
