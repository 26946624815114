



















































import axios, { AxiosResponse, AxiosError } from 'axios';
import EventBus, { ACTIVATE_ALL_EVENT, SHOW_TOAST_MESSAGE } from '../core/event-bus';
import { ApiErrorHandler } from '../common/ApiErrorHandler';
import { Component, Vue } from 'vue-property-decorator';
import { Scroll } from '../directives/Scroll';
import { LayoutSideMenu } from '@frontiers-vue/layout';
import { SideMenu, SideMenuItem } from '@frontiers-vue/side-menu';
import { SwitcherBlock } from '@frontiers-vue/switcher';
import PreferencesBlock from '../components/PreferencesBlock.vue';
import { ISubscriptionBlock, ISubscriptionPreferences } from '../core/models';

@Component({
  components: {
    LayoutSideMenu, SideMenu, SideMenuItem, SwitcherBlock,
    PreferencesBlock,
  },
  directives: {
    scroll: new Scroll(),
  },
})
export default class Preferences extends Vue {
  private get getRefNameLastBlock(): string {
    return this.getRefName(this.subscriptionPreferences[this.subscriptionPreferences.length - 1]);
  }

  private isFromEmail: boolean = false;
  private subscriptionPreferences: ISubscriptionBlock[] = [];
  private isOnGlobalSwitcher: boolean = true; 
  private allDisabled: boolean = false;
  private queryString: string = '';
  private email: string = '';

  private globalSwitcher_changed(code: string, stateOff: boolean): void {
    this.isOnGlobalSwitcher = stateOff;
    this.allDisabled = !stateOff;
    this.saveGlobalStatus(!stateOff);
  }

  private activateGlobalSwitcher(): void {
    this.isOnGlobalSwitcher = true;
    this.allDisabled = false;
    this.saveGlobalStatus(false);
  }

  private saveGlobalStatus(globalUnsubscribeDisabled: boolean): void {

    let url = '';

    if (!globalUnsubscribeDisabled) {
      url = `/emails/api/v1/global-unsubscribe-remove`;
    } else {
      url = `/emails/api/v1/global-unsubscribe-add`;
    }

    const token = encodeURIComponent(this.$route.params.urlKey);

    let data;
    if (token && token !== 'undefined') {
      data = {
        UrlKey: token
      };
    } else {
      data = {
        UrlKey: null
      };
    }

    axios.post(url, data)
      .then((response) => {
        EventBus.$emit(SHOW_TOAST_MESSAGE, 'Your changes are saved automatically', 'Info');
      })
      .catch((error: AxiosError) => {
        EventBus.$emit(SHOW_TOAST_MESSAGE, 'Something went wrong', 'Error');
      });
  }

  private getPathWithHash(block: ISubscriptionBlock): string {
    return `${window.location.pathname}${this.queryString.length > 0 ?
      '?' : ''}${this.queryString}#${this.getRefName(block)}`;
  }

  private smoothScroll(refId: string): void {
    const section = this.$refs[refId] as HTMLElement[];
    const windowWidth = window.innerWidth;

    const adjustmentTop = windowWidth > 768 ? 80 : 66;
    // in mobile we have to wait until the menu is closed
    const timeOutMenu = windowWidth > 768 ? 0 : 410; // 400: time transition to hide the menu

    setTimeout(() => {
      const sectionPosition = section[0].offsetTop;
      const offsetPosition = sectionPosition - adjustmentTop;

      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

      if (supportsNativeSmoothScroll) {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo(0, offsetPosition);
      }

    }, timeOutMenu);
  }

  private loadSubcriptionPreferences(): void {
    const token = encodeURIComponent(this.$route.params.urlKey);
    let url = `/emails/api/v1/preferences`;

    if (token && token !== 'undefined') {
      this.isFromEmail = true;
      url = url + `/${token}`;
    }

    axios.get(url)
      .then((response: AxiosResponse<ISubscriptionPreferences>) => {
        this.email = response.data.email;
        this.isOnGlobalSwitcher = !response.data.isGloballyUnsubscribed;
        this.allDisabled = response.data.isGloballyUnsubscribed;
        this.subscriptionPreferences = response.data.categories;
        this.setStatusBaseOnUrl();
      })
      .catch((error: AxiosError) => {
        ApiErrorHandler.handleApiError(error, this.$router); 
      });
  }

  private getRefName(block: ISubscriptionBlock) {
    return 'block' + block.categoryId;
  }

  private setStatusBaseOnUrl(): void {
    const queryParts = [];
    for (const prop of Object.keys(this.$route.query)) {
      queryParts.push(`${prop}=${this.$route.query[prop]}`);
    }
    this.queryString = queryParts.join('&');

    if (this.queryString === 'wf=fromEmail') { this.isFromEmail = true; }

    let hash = this.$route.hash.toLowerCase();

    if (!hash) {
      hash = '#block' + this.subscriptionPreferences[0].categoryId;
      this.$router.push({ hash });
    }
    setTimeout(() => {
      this.smoothScroll(hash.substring(1));
    }, 10);
  }

  private created(): void {
    document.title = this.$route.meta.title;
    this.loadSubcriptionPreferences();
    EventBus.$on(ACTIVATE_ALL_EVENT, this.activateGlobalSwitcher);
  }
}
