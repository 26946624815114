import { AxiosError, AxiosResponse } from 'axios';
import VueRouter from 'vue-router';

export class ApiErrorHandler {

  public static handleApiError(error: AxiosError, router: VueRouter | null = null, onErrorFunc: any = null): void {
    console.log(error);
    const iError = ApiErrorHandler.getMappedError(parseInt(error.request.status, 0));
    // @ts-ignore
    let errorMessage = iError.message || error.response.statusText;

    if (iError.reload) {
      window.location.reload();
    }

    if (typeof onErrorFunc === 'function') {
      onErrorFunc(iError);
    } else {
      if (iError.code === 400) {
        // @ts-ignore
        for (const property in error.response.data) {
          // @ts-ignore
          errorMessage += property + ':' + error.response.data[property];
        }
      }

      if (iError.redirect) {
        if (router) {
          router.push({
            name: 'Error Page',
            params: {
              errorCode: iError.code.toString(),
              errorMessage,
            },
          });
        } else {
          // @ts-ignore
          throw new Error(error.message);
        }
      }
    }
  }
  private static genericError: IInternalError = { code: 500, message: 'Error 500', reload: false, redirect: true };
  private static error404: IInternalError = { code: 404, message: 'Resource not found', reload: false, redirect: true };
  private static error403: IInternalError = { code: 403, message: 'You don\'t have permissions to view this page.', reload: false, redirect: true };
  private static error401: IInternalError = { code: 401, message: 'Unauthorized', reload: true, redirect: false };
  private static error400: IInternalError = { code: 400, message: 'API error', reload: false, redirect: true };
  private static errors: IInternalError[] = [ApiErrorHandler.error404, ApiErrorHandler.error403,
  ApiErrorHandler.error401, ApiErrorHandler.error400];

  private static getMappedError(code: number): IInternalError {
    for (let i = 0; i < ApiErrorHandler.errors.length; i += 1) {
      if (code === ApiErrorHandler.errors[i].code) {
        return ApiErrorHandler.errors[i];
      }
    }
    return this.genericError;
  }
}

interface IInternalError {
  code: number;
  message: string;
  reload: boolean;
  redirect: boolean;
}
